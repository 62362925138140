<template>
	<div class="wrapper">
		<section class="agreement-section">
			<div class="container">
				<div class="agreement">
					<tk-button @click="goBack" kind="back-button">
						<template #icon-before>
							<tk-svg type="arrow-top" />
						</template>
						Назад
					</tk-button>
				</div>
				<h1 v-if="!loading" class="section-title">{{ titleArticle }}</h1>
				<p v-if="content" class="text" v-html="content.content" />
			</div>
		</section>
	</div>
</template>

<script>
export default {
	data() {
		return {
			content: null,
			loading: true,

      getAbortController: new AbortController(),
		}
	},
	async beforeMount() {
		const response = await this.$api.articles.get({
			id: this.$route.params.alias
		}, { controller: this.getAbortController })

		if (response && response.status) {
			this.content = response.response
		}

		this.loading = false
	},
  beforeUnmount() {
    this.getAbortController.abort()
  },
	computed: {
		titleArticle() {
			return this.content ? this.content.title : 'Статья не найдена!'
		}
	},
  methods: {
    goBack() {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        window.close()
      }
    }
  }
}
</script>
